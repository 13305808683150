<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'
import type { strapiNavigationItem } from '~/interfaces/strapi.interface'
import { useStore } from '~/store/main'

const route = useRoute()
const store = useStore()
const gtm = useGtm()
const { t } = useI18n()

defineProps<{
  top?: strapiNavigationItem[]
  menu?: strapiNavigationItem[]
}>()

const { status } = useAuth()

const open = ref(false)
const isLaptop = useMediaQuery('(min-width: 1280px)')
const isMobile = useMediaQuery('(max-width: 1279px)')

const layout: { data: { attributes: object } } | undefined = inject('layout')
const loginRegister: { data: { attributes: object } } | undefined =
  inject('loginRegister')

const closeMenu = () => {
  if (store.isMenuAccountOpen) {
    open.value = false
    store.$patch({
      isMenuAccountOpen: false,
    })
  } else {
    open.value = !open.value
  }
}

watch(
  () => route.fullPath,
  () => {
    open.value = false
    store.$patch({
      isMenuAccountOpen: false,
    })

    isHeroHome.value = false
    const homeHero = document.querySelector('.home-hero__bg')
    if (homeHero) {
      isHeroHome.value = true
      window.addEventListener('scroll', () => {
        isScrolled.value = window.scrollY > homeHero.offsetHeight
      })
    }
  }
)

watch(
  () => open.value,
  () => {
    if (open.value) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }
)

// temporaire

const router = useRouter()

const gtmLogin = () => {
  gtm.trackEvent({
    event: 'login',
    action: 'click',
    value: 'Click login button in header',
  })

  router.push({ name: t('routes.login') })
}

const isOpenAccount = ref(false)
const buttons = ref()
const clickOutside = ref(false)
onClickOutside(buttons, () => {
  clickOutside.value = true
  isOpenAccount.value = false
  setTimeout(() => {
    clickOutside.value = false
  }, 100)
})

const clickAccount = () => {
  if (!clickOutside.value) isOpenAccount.value = true
  clickOutside.value = false
}

const isScrolled = ref(false)
const isHeroHome = ref(true)

onMounted(() => {
  const homeHero = document.querySelector('.home-hero__bg')
  isHeroHome.value = !!homeHero
  if (homeHero) {
    window.addEventListener('scroll', () => {
      isScrolled.value = window.scrollY > 100
    })
  }
})

const config = useRuntimeConfig()

const wealthTwicLink = ref('')

if (config.public.env === 'development' || config.public.env === 'staging') {
  wealthTwicLink.value = 'wealthLocal/'
} else if (config.public.env === 'preprod') {
  wealthTwicLink.value = 'wealthPreprod/'
} else {
  wealthTwicLink.value = ''
}

const getMenuLogoMobile = computed(() => {
  const type =
    isHeroHome.value &&
    !isScrolled.value &&
    layout.value.data.attributes.logo_header_white.data
      ? 'logo_header_white'
      : 'logo_header_blue'
  return `${layout.value.data.attributes[type].data.attributes.hash}${layout.value.data.attributes[type].data.attributes.ext}`
})
</script>

<template>
  <div
    :class="[
      isHeroHome ? 'fixed lg:sticky' : 'sticky',
      { 'white-account': isHeroHome && !isScrolled },
    ]"
    class="left-0 right-0 top-0 z-[100]"
  >
    <div class="bg-primary relative border-b border-grey-500 max-lg:hidden">
      <UContainer>
        <div class="flex items-center justify-end">
          <ak-navigation
            type="top"
            item-classes="hover:bg-white/20 p-8 font-montserrat text-secondary-light text-12 font-bold"
            :menu="top"
            v-if="top"
          />
        </div>
      </UContainer>
    </div>
    <header
      class="relative z-[50] max-lg:py-18"
      :class="[
        isHeroHome
          ? 'lg:border-b lg:border-grey-100 lg:bg-white'
          : 'border-b border-grey-100 bg-white',
        { 'bg-white': isHeroHome && isScrolled },
      ]"
      data-cy="header"
    >
      <UContainer>
        <div class="flex w-full items-center justify-between">
          <div
            class="flex items-center max-lg:justify-between"
            :class="{ 'max-lg:w-full': status !== 'authenticated' }"
          >
            <NuxtLink
              v-if="layout.data.attributes.eurazeo_url"
              :to="layout.data.attributes.eurazeo_url"
              data-cy="header-logo-link"
            >
              <picture
                v-if="layout && layout.data.attributes.logo_header_blue.data"
              >
                <source
                  media="(max-width: 767px)"
                  :srcset="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${getMenuLogoMobile}?twic=v1/cover-max=240`"
                />
                <img
                  data-cy="header-logo-img"
                  class="block h-auto w-110 max-w-full lg:w-160"
                  :alt="
                    layout.data.attributes.logo_header_blue.data.attributes
                      .alternativeText
                  "
                  :src="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${layout.data.attributes.logo_header_blue.data.attributes.hash}${layout.data.attributes.logo_header_blue.data.attributes.ext}?twic=v1/cover-max=320`"
                />
              </picture>
            </NuxtLink>
            <button
              v-if="menu && menu.length"
              class="flex lg:hidden"
              :class="{ 'max-lg:ml-20': status === 'authenticated' }"
              @click="closeMenu"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 7.5H21M3 12.5H21M3 17.5H21"
                  :stroke="
                    !isHeroHome || (isHeroHome && isScrolled)
                      ? '#002F54'
                      : '#F7F7F7'
                  "
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          <Transition name="mobile-menu">
            <div
              v-show="open"
              class="z-50 self-stretch max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:right-0 max-lg:top-0 max-lg:overflow-auto max-lg:bg-white lg:!flex lg:items-center lg:justify-center"
            >
              <div
                class="flex items-center px-container-mobile py-18 max-lg:justify-between lg:hidden"
              >
                <NuxtLink to="/">
                  <picture
                    v-if="
                      layout && layout.data.attributes.logo_header_blue.data
                    "
                  >
                    <source
                      media="(max-width: 767px)"
                      :srcset="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${layout.data.attributes.logo_header_blue.data.attributes.hash}${layout.data.attributes.logo_header_blue.data.attributes.ext}?twic=v1/cover-max=240`"
                    />
                    <img
                      class="block h-auto w-110"
                      :alt="
                        layout.data.attributes.logo_header_blue.data.attributes
                          .alternativeText
                      "
                      :src="`https://wealtheurazeo.twic.pics/${wealthTwicLink}${layout.data.attributes.logo_header_blue.data.attributes.hash}${layout.data.attributes.logo_header_blue.data.attributes.ext}?twic=v1/cover-max=320`"
                    />
                  </picture>
                </NuxtLink>
                <button
                  v-if="menu && menu.length"
                  class="flex lg:hidden"
                  @click="closeMenu"
                >
                  <img
                    class="w-24"
                    src="@/assets/images/close.svg"
                    alt="Close icon"
                  />
                </button>
              </div>
              <div class="max-lg:pb-[192px] lg:h-full">
                <ak-navigation
                  type="main"
                  item-classes="lg:min-h-[75px] text-primary max-lg:px-container-mobile relative max-lg:py-40 max-lg:flex max-lg:justify-between max-lg:text-left max-lg:border-b max-lg:border-[#C3C3C3] lg:font-semibold text-18 leading-21 font-medium lg:text-p2 max-lg:after:hidden after:content-[''] after:opacity-0 group-hover:after:opacity-100 after:absolute after:h-[3px] after:bottom-0 after:w-28 after:left-1/2 after:-translate-x-1/2 after:rounded-full after:bg-secondary-500"
                  items-classes="max-lg:mt-40 max-lg:w-full header-navigation flex max-lg:flex-col lg:items-center lg:justify-center lg:gap-60"
                  :menu="menu"
                />
                <div class="bg-primary lg:hidden">
                  <ak-navigation
                    type="top"
                    items-classes="max-lg:flex-col"
                    item-classes="px-container-mobile flex justify-between items-center py-12 bg-primary font-montserrat text-secondary-light text-12 font-bold"
                    :menu="top"
                    v-if="top"
                  />
                </div>
              </div>
              <div
                v-if="status !== 'authenticated'"
                class="fixed bottom-0 left-0 right-0 flex flex-col bg-[#F9F9F9] px-container-mobile py-42 shadow-[0_4px_24px_rgba(0,0,0,0.17)] lg:hidden"
              >
                <UButton
                  block
                  color="primary"
                  :to="{ name: t('routes.login') }"
                  @click.prevent="gtmLogin"
                >
                  {{ $t('layout.header.login') }}
                </UButton>
                <UButton
                  block
                  class="mt-24"
                  color="primary"
                  variant="outline"
                  :to="{ name: t('routes.partnerOnboardingRegister') }"
                >
                  {{ $t('layout.header.register_partner') }}
                </UButton>
              </div>
            </div>
          </Transition>
          <div
            v-if="status === 'authenticated'"
            class="flex items-center justify-end"
          >
            <AuthHeaderAccount />
          </div>
          <template v-else>
            <div
              class="z-10 flex max-lg:hidden lg:justify-end"
              data-cy="auth-buttons"
            >
              <UButton
                v-if="status !== 'authenticated'"
                variant="text"
                color="primary"
                class="mt-10 max-lg:w-200 lg:mt-0"
                :to="{ name: t('routes.partnerOnboardingRegister') }"
                data-cy="header-register-button"
              >
                {{ $t('layout.header.register_partner') }}
              </UButton>
              <UButton
                v-if="status !== 'authenticated'"
                class="max-lg:w-200 lg:ml-12"
                color="primary"
                :to="{ name: t('routes.login') }"
                data-cy="header-login-button"
                @click.prevent="gtmLogin"
              >
                {{ $t('layout.header.login') }}
              </UButton>
            </div>
          </template>
        </div>
      </UContainer>
    </header>
  </div>
</template>

<style>
.mobile-menu-enter-active,
.mobile-menu-leave-active {
  transition: transform 0.5s ease;
}

.mobile-menu-enter-from,
.mobile-menu-leave-to {
  transform: translateX(100%);
}
</style>

<style lang="scss" scoped>
:deep(.header-navigation) {
  .router-link-active {
    @apply after:opacity-100 max-lg:border-b max-lg:border-secondary-light;
  }
}

.white-account {
  :deep(.account__button),
  :deep(.account-switch__button) {
    @apply max-lg:text-white !important;
  }
}
</style>
