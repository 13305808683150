<script lang="ts" setup>
import type { strapiNavigationItem } from '~/interfaces/strapi.interface'
import type { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types/v4'
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import { ViewStructureUp, NavArrowDown } from '@iconoir/vue'
const { locale } = useI18n()
const config = useRuntimeConfig()
const { findOne } = useStrapi()
const { status } = useAuth()
const route = useRoute()

const props = defineProps<{
  menu?: strapiNavigationItem[]
  itemClasses: string
  itemsClasses: string
  type?: string
}>()

watch(
  () => route.fullPath,
  () => {
    resourcesOpen.value = false
  }
)

const { data: resources } = await useCachedAsyncData<Strapi4ResponseMany>(
  `resources_menu_${locale.value}`,
  () =>
    findOne('resources-menu', {
      populate:
        'column_1, column_1.link, column_1.link.page, link.page, column_1.icon, column_2, column_2.link, column_2.link.page, link.page, column_2.icon',
      locale: locale.value as StrapiLocale,
    }),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

const transformToLink = (item: strapiNavigationItem) => {
  if (item.related) {
    if (item.related.__contentType === 'api::homepage.homepage') return '/'
    else if (item.related.__contentType === 'api::page.page')
      return `/${item.related.url}`
    else if (item.related.__contentType === 'api::guide.guide')
      return `/guide/${item.related.guide_category.slug}/${item.related.slug}`
    else if (item.related.__contentType === 'api::article-list.article-list')
      return '/articles'
    else return item.path
  } else if (item.external || item.type === 'WRAPPER') {
    return item.path
  }
  return undefined
}

const resourcesOpen = ref(false)

const leaveResources = (event) => {
  if (window.matchMedia('(min-width: 768px)').matches) {
    if (
      !event.relatedTarget ||
      (event.relatedTarget && !event.relatedTarget.closest('.resources-menu'))
    )
      resourcesOpen.value = false
  }
}
const leaveResourcesMenu = (event) => {
  if (window.matchMedia('(min-width: 768px)').matches) {
    resourcesOpen.value = false
  }
}
const overResources = () => {
  if (window.matchMedia('(min-width: 768px)').matches) {
    resourcesOpen.value = true
  }
}

const clickResources = () => {
  resourcesOpen.value = !resourcesOpen.value

  if (resourcesOpen.value) {
    setTimeout(() => {
      document
        .querySelector('.resources-button')
        .scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
}

const activeMobile = ref()
</script>

<template>
  <nav class="lg:h-full" v-if="menu" data-test="menu">
    <ul class="flex lg:h-full" :class="[itemsClasses]">
      <li
        @click="
          activeMobile === item.id
            ? (activeMobile = null)
            : (activeMobile = item.id)
        "
        class="lg:h-full"
        :class="[
          type === 'top' ? 'group max-lg:bg-white' : 'group lg:overflow-hidden',
        ]"
        v-for="item in menu"
        :key="item.id"
      >
        <div
          class="resources-button block w-full lg:flex lg:h-full lg:items-center"
          @click="clickResources"
          @mouseleave="leaveResources"
          v-if="item.path === '/resources'"
          type="button"
        >
          <button
            type="button"
            class="flex w-full items-center justify-between max-lg:flex lg:h-full"
            :class="itemClasses"
            @mouseover="overResources"
          >
            <span>{{ item.title }}</span>
            <NavArrowDown
              :class="{ 'rotate-180': resourcesOpen }"
              class="h-16 w-16 transition duration-300 lg:h-24 lg:w-24"
            />
          </button>
        </div>

        <div class="lg:h-full" v-else>
          <button
            class="inline-flex items-center max-lg:w-full lg:flex lg:h-full lg:items-center"
            type="button"
            :class="itemClasses"
            v-if="item.items && item.items.length"
          >
            {{ item.title }}
            <NavArrowDown
              :class="[
                type === 'top' ? 'h-14 w-14' : 'h-16 w-16 lg:h-24 lg:w-24',
              ]"
              class="ml-8 transition duration-300 group-hover:rotate-180"
            />
          </button>
          <nuxt-link
            v-else
            class="inline-flex items-center max-lg:w-full lg:h-full"
            :class="[
              itemClasses,
              {
                'bg-white/20': type === 'top' && item.path === '/',
              },
            ]"
            data-test="menuItem"
            :to="transformToLink(item)"
            :target="item.newTab ? '_blank' : undefined"
            :data-cy="`nav-item-link-${transformToLink(item)}`"
          >
            {{ item.title }}
          </nuxt-link>
        </div>
        <div
          @mouseleave="leaveResourcesMenu"
          class="resources-menu overflow-hidden lg:absolute lg:left-0 lg:right-0 lg:top-full"
          :class="resourcesOpen ? '' : 'pointer-events-none'"
        >
          <Transition name="resources">
            <div
              class="bg-white lg:border-b lg:border-grey-100"
              v-show="resourcesOpen"
              v-if="item.path === '/resources'"
            >
              <UContainer class="py-16 lg:py-80">
                <AkRow>
                  <AkCol :cols="12" :cols-lg="4">
                    <p
                      v-if="
                        resources &&
                        resources.data &&
                        resources.data.attributes.column_1_title
                      "
                      class="mb-32 text-14 font-bold leading-none tracking-[0.14px] text-secondary-light"
                    >
                      {{ resources.data.attributes.column_1_title }}
                    </p>
                    <div class="flex flex-col gap-32">
                      <div
                        v-if="resources && resources.data"
                        v-for="link in resources.data.attributes.column_1"
                        class="flex items-start"
                      >
                        <AkImage
                          class="h-24 w-24 object-contain"
                          :image="link.icon"
                          v-if="link.icon && link.icon.data"
                        />
                        <ViewStructureUp class="text-primary" v-else />
                        <AkLink :link="link.link" class="ml-24 !border-0">
                          <p
                            class="text-primary font-montserrat text-18 font-semibold leading-24"
                            v-if="link.title"
                          >
                            {{ link.title }}
                          </p>
                          <p class="mt-6 text-p4 text-grey" v-if="link.text">
                            {{ link.text }}
                          </p>
                        </AkLink>
                      </div>
                    </div>
                  </AkCol>
                  <AkCol :cols="12" :cols-lg="4">
                    <p
                      v-if="
                        resources &&
                        resources.data &&
                        resources.data.attributes.column_2_title
                      "
                      class="mb-32 text-14 font-bold leading-none tracking-[0.14px] text-secondary-light"
                    >
                      {{ resources.data.attributes.column_2_title }}
                    </p>
                    <div class="flex flex-col gap-32">
                      <div
                        v-if="resources && resources.data"
                        v-for="link in resources.data.attributes.column_2"
                        class="flex items-start"
                      >
                        <AkImage
                          class="h-24 w-24 object-contain"
                          :image="link.icon"
                          v-if="link.icon && link.icon.data"
                        />
                        <ViewStructureUp class="text-primary" v-else />
                        <AkLink :link="link.link" class="ml-24 !border-0">
                          <p
                            class="text-primary font-montserrat text-18 font-semibold leading-24"
                            v-if="link.title"
                          >
                            {{ link.title }}
                          </p>
                          <p class="mt-6 text-p4 text-grey" v-if="link.text">
                            {{ link.text }}
                          </p>
                        </AkLink>
                      </div>
                    </div>
                  </AkCol>
                </AkRow>
              </UContainer>
            </div>
          </Transition>
        </div>
        <div
          :class="[
            type === 'main' ? 'lg:top-full' : 'lg:top-30',
            { 'max-lg:flex': activeMobile === item.id },
          ]"
          v-if="item.items && item.items.length"
          class="z-[100] hidden lg:absolute lg:left-0 lg:right-0 lg:border-y lg:border-grey-500 lg:group-hover:block"
        >
          <div
            :class="[
              type === 'top'
                ? 'bg-primary text-white'
                : 'text-primary bg-white',
            ]"
            class="py-10 max-lg:w-full lg:py-50"
          >
            <UContainer>
              <div
                :class="[
                  type === 'top'
                    ? 'justify-end lg:flex lg:pr-100'
                    : 'lg:pl-180',
                ]"
              >
                <div class="max-lg:hidden" v-if="type === 'top'">
                  <p class="mr-80 text-20">{{ item.title }}</p>
                </div>
                <div>
                  <li v-for="subItem in item.items" :key="subItem.id">
                    <nuxt-link
                      class="text-12"
                      :to="transformToLink(subItem)"
                      :target="subItem.newTab ? '_blank' : undefined"
                      :data-cy="`nav-item-link-${transformToLink(item)}`"
                    >
                      {{ subItem.title }}
                    </nuxt-link>
                  </li>
                </div>
              </div>
            </UContainer>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
.resources-enter-active,
.resources-leave-active {
  transition: transform 0.3s ease;
}

.resources-enter-from,
.resources-leave-to {
  transform: translateY(-100%);
}
</style>
