<script lang="ts" setup>
import type { strapiNavigationItem } from '~/interfaces/strapi.interface'
import AkImage from '~/components/Ak/AkImage.vue'
import { useStore } from '~/store/main'

const store = useStore()
const gtm = useGtm()
const route = useRoute()

defineProps<{
  menu?: strapiNavigationItem[]
  menuSeo?: strapiNavigationItem[]
}>()

const { status } = useAuth()

const layout: { data: { attributes: object } } | undefined = inject('layout')

const transformToLink = (item: strapiNavigationItem) => {
  if (item.related) {
    if (item.related.__contentType === 'api::homepage.homepage') return '/'
    else if (item.related.__contentType === 'api::page.page')
      return `/${item.related.url}`
    else if (item.related.__contentType === 'api::guide.guide')
      return `/guide/${item.related.guide_category.slug}/${item.related.slug}`
    else if (item.related.__contentType === 'api::article-list.article-list')
      return '/articles'
    else return item.path
  } else if (item.external || item.type === 'WRAPPER') {
    return item.path
  }
  return undefined
}

const cookieConsent = useCookie('CookieConsent')
const consent = useCookie('consent')

const viewCookie = () => {
  if (window.Cookiebot) {
    window.Cookiebot.renew()
  }
}

onMounted(() => {
  if (!cookieConsent.value) {
    if (window.Cookiebot) Cookiebot.renew()
    if (!consent.value) {
      store.$patch({
        isConsentOpen: true,
      })
    }
  }

  if (!consent.value) {
    store.$patch({
      isConsentOpen: true,
    })
  }
})

const clickSocial = (social) => {
  gtm.trackEvent({
    event: 'socialNetwork',
    action: 'click on social icon',
    value: `User click on social icon: ${social.link}`,
  })
  window.open(social.link, '_blank')
}

const goToLink = (link: string, newTab: boolean) => {
  if (newTab) window.open(link, '_blank')
  else window.open(link, '_self')
}

const nuxtLink = resolveComponent('NuxtLink')

const isHome = computed(() => {
  return route.name === 'index'
})

const openSeo = ref(false)
</script>

<template>
  <footer v-if="layout" class="bg-primary relative z-30 py-20">
    <UContainer>
      <AkRow class="mt-20">
        <template v-if="menu">
          <AkCol data-test="menu" cols-lg="3" :cols="12">
            <a
              v-if="layout.data.attributes.eurazeo_url"
              :href="layout.data.attributes.eurazeo_url"
              data-cy="footer-logo-link"
            >
              <AkImage
                :image="layout.data.attributes.logo_footer"
                class="mb-16 h-50"
                data-cy="footer-logo-img"
              />
            </a>
            <div
              v-if="layout.data.attributes.socials"
              class="mb-22 mt-20 flex gap-20"
            >
              <a
                v-for="social in layout.data.attributes.socials"
                :key="social.id"
                :href="social.link"
                target="_blank"
                data-cy="footer-social-link"
                @click.prevent="clickSocial(social)"
              >
                <AkImage v-if="social.icon" :image="social.icon" class="w-20" />
              </a>
            </div>
            <p class="mb-8 text-p4 text-white opacity-70">
              &copy; {{ new Date().getFullYear() }}
              {{ $t('layout.footer.copyright') }}
            </p>
            <p
              v-if="layout.data.attributes.address"
              class="mb-8 text-p4 text-white opacity-70"
            >
              {{ layout.data.attributes.address }}
            </p>
            <p class="text-p4 text-white opacity-70">
              {{ $t('layout.footer.legal') }}
            </p>
          </AkCol>
          <AkCol
            v-for="(item, index) in menu"
            :key="item.id"
            data-test="menu"
            cols-lg="3"
            :cols="12"
          >
            <div>
              <p class="mb-26 text-p2 uppercase text-white">
                {{ item.title }}
              </p>
              <ul v-if="item.items" class="flex flex-col gap-y-26">
                <li
                  v-for="subItem in item.items"
                  :key="subItem.id"
                  class="text-p2 font-semibold"
                >
                  <div
                    v-if="
                      subItem.type === 'WRAPPER' &&
                      subItem.path &&
                      subItem.path.indexOf('cookies') > -1
                    "
                    class="cursor-pointer text-left text-white opacity-70"
                    data-cy="cookies-handle"
                    @click="viewCookie"
                  >
                    {{ subItem.title }}
                  </div>
                  <component
                    :is="isHome || (!isHome && index === 0) ? 'a' : 'button'"
                    v-else-if="subItem.type === 'EXTERNAL'"
                    class="cursor-pointer text-left text-white opacity-70"
                    :href="
                      isHome || (!isHome && index === 0)
                        ? transformToLink(subItem)
                        : ''
                    "
                    :target="subItem.newTab ? '_blank' : undefined"
                    data-cy="footer-menu-link"
                    @click="
                      isHome || (!isHome && index === 0)
                        ? ''
                        : goToLink(transformToLink(subItem), subItem.newTab)
                    "
                  >
                    {{ subItem.title }}
                  </component>
                  <component
                    :is="
                      isHome || (!isHome && index === 0) ? nuxtLink : 'button'
                    "
                    v-else
                    class="text-white opacity-70"
                    :to="
                      isHome || (!isHome && index === 0)
                        ? transformToLink(subItem)
                        : ''
                    "
                    :target="subItem.newTab ? '_blank' : undefined"
                    data-cy="footer-menu-link"
                    @click="
                      isHome || (!isHome && index === 0)
                        ? ''
                        : goToLink(transformToLink(subItem), subItem.newTab)
                    "
                  >
                    {{ subItem.title }}
                  </component>
                </li>
              </ul>
            </div>
          </AkCol>
        </template>
      </AkRow>

      <div class="mt-50" v-if="menuSeo && menuSeo.length">
        <div>
          <AkRow class="mt-20">
            <AkCol
              v-for="item in menuSeo"
              :key="item.id"
              data-test="menu"
              cols-lg="3"
              :cols="12"
            >
              <div>
                <p
                  v-if="item.path === '/'"
                  class="mb-26 text-p2 uppercase text-white"
                >
                  {{ item.title }}
                </p>

                <a
                  v-else-if="item.type === 'EXTERNAL'"
                  class="mb-26 inline-block text-p2 uppercase text-white"
                  :href="transformToLink(item)"
                  :target="item.newTab ? '_blank' : undefined"
                >
                  {{ item.title }}
                </a>
                <nuxt-link
                  v-else
                  class="mb-26 inline-block text-p2 uppercase text-white"
                  :to="transformToLink(item)"
                  :target="item.newTab ? '_blank' : undefined"
                >
                  {{ item.title }}
                </nuxt-link>

                <ul v-if="item.items" class="flex flex-col gap-y-26">
                  <li
                    v-for="subItem in item.items"
                    :key="subItem.id"
                    class="text-p2 font-semibold"
                  >
                    <a
                      v-if="subItem.type === 'EXTERNAL'"
                      class="cursor-pointer text-left text-white opacity-70"
                      :href="transformToLink(subItem)"
                      :target="subItem.newTab ? '_blank' : undefined"
                      data-cy="footer-menu-link"
                    >
                      {{ subItem.title }}
                    </a>
                    <nuxt-link
                      v-else
                      class="text-white opacity-70"
                      :to="transformToLink(subItem)"
                      :target="subItem.newTab ? '_blank' : undefined"
                      data-cy="footer-menu-link"
                    >
                      {{ subItem.title }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </AkCol>
          </AkRow>
        </div>
      </div>
    </UContainer>
  </footer>
</template>
